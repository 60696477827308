.social-form {
    display: flex;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    height: 100%;

    &__container {
        flex-grow: 1;
        display: flex;
        flex-direction: row;
    }

    &__container {
        display: flex;
        flex-wrap: wrap;
        vertical-align:top;

        &__left {
            flex: 0 0 33%;
            margin-right: 2em;
        }

        &__right {
            flex: 1;
            background: #f3f2f0;
            padding: 1em;
            vertical-align:top;
        }
    }

    &__preview {
        width: 320px;
        margin-right: 1em;
        border: 1px solid #dededc;
        border-radius: 10px;
        padding: 1em;
        background: #FFF;
        display: inline-block;
        margin-bottom: 1em;
        vertical-align: top;

        &__header {
            display: flex;
            margin-bottom: 1em;
        }

        &__icon {
            border-radius: 100px;
            width: 48px;
            height: 48px;
            display: blocl;
            line-height: 48px;
            text-align: center;
            background-color: pink;
            margin-right: 0.5em;
        }

        &__name {
            font-weight: bold;
        }

        &__role {
            color: #666666;
            font-size: 12px;
            margin-top: 0.25em;
        }

        &__text {
            margin-bottom: 1em;
        }

        img {
            width: 100%;
        }
    }

    &__footer {
        display: flex;
        padding-bottom: 2em;
        padding-top: 2em;

        &__center {
            flex-grow: 1;
        }
    }
}