@import "./vars.scss";

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;600;700&display=swap');


* {
    box-sizing: border-box;
  }


body {
    font-family: 'Poppins', sans-serif;
    color:#333333;
}

button,.button {
    background-color: $clr-primary;
    color: #FFF;
    border:0;
    padding: 1em 2.5em 1em;
    font-size: 1em;
    border-radius: 2em;
    display:inline-block;
    cursor:pointer;
    text-transform:uppercase;

    &:hover {
        opacity:0.8;
    }
}

h1 {
    font-size: 52px;
    line-height:1.1;
    margin-top: 0;
    margin-bottom: 0.5em;
}

h2 {
    font-size: 36px;
    line-height:1.1;
    margin-top: 0;
    margin-bottom: 0.5em;
}

a {
    color: $clr-primary;
}