.mobile-placeholder {
    width:100vw;
    padding: 1em;
    .logo {
      background-image:url(../../logo.png);
      height: 2.5em;
      background-size: auto 100%;
      background-position: center center;
      background-repeat:no-repeat;
      margin-bottom: 2em;
    }
  }