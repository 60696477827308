@import "../../styles/vars.scss";

.steps {
    margin-top: 32px;

    &__item {

        &__container {
            display:flex;
            align-items: center;    
            cursor:pointer;
            &:hover {
                opacity:0.8;
            }
        }

        &__icon {
            width: 32px;
            line-height: 32px;
            text-align:center;
            border:1px solid #E8E8E8;
            border-radius: 100px;

            &--done {
                background-color: $clr-primary;
                color:#FFF;
                border:0;
                font-size: 0;
                background-image:url(./check.svg);
                background-size: 12px;
                background-position: center center;
                background-repeat:no-repeat;
                
            }
        }

        &__title {
            font-size: 18px;
            cursor:pointer;
            margin-left: 16px;
        }

        &__line-bottom {
            margin-left: 20px;
            height: 20px;
            border-left: 1px dotted #84818A;
        }
    }
}