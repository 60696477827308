.script-form {
    display: flex;
    flex-direction: column;
    margin-left:auto;
    margin-right:auto;
    height: 100%;

    &__preview {
        border: 1px solid rgba(0,0,0,0.2);
        box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.1);

        padding: 1em;
        margin-bottom: 1em;
        color:#333;

        &__logo {
            margin-top: 1em;
        }
    
        &__body {
            padding: 1em;
        }

        table {
            width: 100%;
            border-collapse: collapse;
            
            th, td {
                text-align:left;
                border: 1px solid rgba(0,0,0,0.2);
                padding: 4px;
            }
            th {
                background-color:rgba(0,0,0,0.05)
            }
        }
    }

}