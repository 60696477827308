.description-result {
    width: 900px;
    margin-left:auto;
    margin-right:auto;
    height: 100%;
    display: flex;
    flex-direction: column;

    .form__container {
        flex-grow:1;
        display:flex;
        flex-direction: column;    
    }

    &__preview {
        box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.1);
        border: 1px solid rgba(0,0,0,0.2);
        padding: 1em;
        margin-bottom: 1em;
    }
}