.slides-form {
    display: flex;
    flex-direction: column;
    margin-left:auto;
    margin-right:auto;
    height: 100%;

    &__preview {
       
        .slide {
            border: 1px solid rgba(0,0,0,0.2);
            margin-bottom: 4em;
            padding-bottom: 56.5%;
            color:#000;
            position: relative;
            box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
            font-size: 0.8em;

            &__container {
                position: absolute;
                top: 0px;
                right: 0px;
                left: 0px;
                bottom: 0px;
                height: 100%;
            }
           
            overflow:hidden;

            &__title {
                font-size: 1.3em;
                font-weight: bold;
                margin-bottom: 0.5em;
                overflow:hidden;
                text-overflow: ellipsis;
            }

            &__main {
                max-width: 65%;
                height: 100%;
            }

            &__image {
                height:100%;
                width: 35%;
                background-size:cover;
                background-position:center center;
            }


            li {
                margin-bottom: 0.25em;
            }


            &--cover .slide__container {
                background-color:#f8fd81;
                display:flex;
                align-items:center;
                padding: 2em;

                .slide__event-title {
                    font-weight:bold;
                    font-size: 1.5em;
                    max-width: 75%;
                    flex: 1;
                }

                .slide__deck-title {
                    font-weight: bold;
                    max-width: 75%;
                    font-size: 2.5em;
                    padding-bottom: 0.5em;
                    text-decoration:underline;
                }
            }

            &--0 .slide__container {
                display:flex;
                .slide__main {
                    padding: 2em;
                    background-color:#EEE;
                }
            }

            &--1 .slide__container {
                display:flex;
                flex-direction: row-reverse;

                .slide__main {
                    padding: 2em;
                    background-color:#f8fd81;
                }
            }

            &--2 .slide__container {
                background-color:#f8fd81;
                display:flex;
                flex-direction: row;

                .slide__main {
                    width: 100%;
                    max-width: 100%;

                    padding: 2em;
                    background-color:#f8fd81;

                    display:flex;
                    align-items: center;

                    .slide__title {
                        width: 35%;
                        font-size: 2em;
                    }

                    .slide__content {
                        width: 65%;
                    }
                }
                .slide__image {
                    width:0;
                }
            }

            &--3 .slide__container {
                display:flex;
                flex-direction: row;

                .slide__main {
                    padding: 2em;
                    background-color:#f8fd81;
                }
            }
        }
        table {
            width: 100%;
            border-collapse: collapse;
            
            th, td {
                text-align:left;
                border: 1px solid rgba(0,0,0,0.2);
                padding: 4px;
            }
            th {
                background-color:rgba(0,0,0,0.05)
            }
        }
    }

}