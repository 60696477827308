.news-article-form {
    display: flex;
    flex-direction: column;
    margin-left:auto;
    margin-right:auto;
    height: 100%;

    &__container {
        flex-grow:1;
        display:flex;
        flex-direction: row;    
    }

    &__container {
        display:flex;
        flex-wrap: wrap;

        &__left {
            flex: 0 0 33%; 
        }

        &__right {
            padding-left: 3em;
            flex:1;

        }

    }

    &__preview {
        border: 1px solid rgba(0,0,0,0.2);
        box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.1);

        padding: 1em;
        margin-bottom: 1em;

        &__logo {
            margin-top: 1em;
        }
    
        &__body {
            padding: 1em;
        }
    }


    &__footer {
        display:flex;
        padding-bottom: 2em;
        padding-top: 2em;
        &__center {
            flex-grow:1;
        }
    }
}