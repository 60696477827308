@import "../../styles/vars.scss";

/** Sidebar **/
.app-area__sidebar {
    width: 400px;
    position: fixed;
    top: 0%;
    bottom: 0px;
    padding: 32px;
    color: #202020;
    background: #F6F6F6;
  
    .logo {
      background-image:url(../../logo.png);
      height: 2.5em;
      background-size: auto 100%;
      background-position: left center;
      background-repeat:no-repeat;
    }
  
    .livewall {
      position: absolute;
      bottom: 0px;
      left: 0px;
      right: 32px;
  
      a {
        display: block;
        height: 40px;
        margin-bottom: 16px;
    
        background-size: auto 100%;
        background-repeat: no-repeat;
        background-position: center right;
        width: 100%;
      }
    }
  }
  
  /** Main **/
  .app-area__main {
    position: absolute;
    left: 400px;
    right: 0px;
    top: 0px;
    bottom: 0px;
    padding: 64px;
  
  
  }
  
  /** Elements **/
  
  .loader {
    border: 4px solid rgba(0, 0, 0, 0.1);
    /* Border color of the spinner */
    border-top: 4px solid #b54b7e;
    /* Color of the spinning animation */
    border-radius: 50%;
    /* Makes the border circular */
    width: 40px;
    height: 40px;
    animation: spin 1s linear infinite;
    /* Apply the spinning animation */
    margin: 20px auto;
    /* Center the spinner */
  }
  
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
  
    /* Starting rotation */
    100% {
      transform: rotate(360deg);
    }
  
    /* Ending rotation after one full circle */
  }
  
  
  .form__container {
    
      flex-grow:1;
      display:flex;
      flex-direction: row;    
  
      display:flex;
      flex-wrap: wrap;
  
      &__left {
          flex: 0 0 33%; 
      }
  
      &__right {
          padding-left: 3em;
          flex:1;
  
      }
  
  }
  
  .form__footer{
    display:flex;
          padding-bottom: 2em;
          padding-top: 2em;
          &__center {
              flex-grow:1;
          }            
  }
  