.poster-form { 
   

    &__preview {
        border: 1px solid rgba(0,0,0,0.2);
        box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.1);

        width: 600px;
        height: 848px;
        position: relative;

        .poster-image1 {
            position: absolute;
            top: 0px;
            height: 250px;
            right: 0px;
            width: 300px;
            background-size:cover;
            background-position:center center;
            opacity:0.4;
            filter: grayscale(.1);
        }

        .poster-image3 {
            width: 400px;
            height: 400px;
            background-size:cover;
            background-position:center center;
            position: absolute;
            bottom: 50px;
            height: 280px;
            width: 100%;
            right: 0px;
            opacity:0.8;
            filter: grayscale(.1);
        }

        .poster-container {
            position: absolute;
            top: 100px;
            z-index:2;
        }

        .poster-title {
            padding: 25px;
            font-weight:bold;
            text-transform:uppercase;
            border-left: 20px solid #f8ec00;
            font-size: 40px;
            max-width: 500px;
            z-index:2;
            font-weight: 800;
            color:#FFF;
            background: #000;
        }

        .poster-program {
            font-size: 14px;
            width: 400px; 
            padding-left: 2em;
            font-weight:bold;
        }

        .poster-date {
            position: absolute;
            top: 40px;
            font-size: 35px;
            font-weight:bold;
            padding-left: 50px;
            background-color:#FFF;
        }

        .poster-bar {
            position: absolute;
            top: 250px;
            bottom: 0px;
            right: 0px;
            width: 175px;
            background-color:#f8ec00;
           
        }
    }


    &__footer {
        display:flex;
        padding-bottom: 2em;
        padding-top: 2em;
        &__center {
            flex-grow:1;
        }
    }
}