.details-form {
    width: 600px;
    margin-left:auto;
    margin-right:auto;
    height: 100%;
    display: flex;
    flex-direction: column;

    &__container {
        flex-grow:1;
        display:flex;
        flex-direction: column;    
    }

    &__footer {
        display:flex;
        &__center {
            flex-grow:1;
        }
    }
}